.f-body {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-containter {
    max-width: 62vw;
    width: 100%;
}

.f-containter .steps {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.f-containter .titles {
    display: flex;
    width: 102%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: bold;
    right: 5px;
}

.steps .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    color: #999;
    font-size: 26px;
    font-weight: 500;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #e0e0e0;
    z-index: 1;
}

.steps .circle.active {
    border-color: #4070f4;
    color: #4070f4;
}

.steps .progress-bar {
    position: absolute;
    height: 10px;
    width: 100%;
    background: #e0e0e0;
    z-index: 0;
}

.progress-bar .indicator {
    position: absolute;
    height: 100%;
    background: #4070f4;
}